<template>
  <div class="d-inline-flex justify-center align-center marker" @click="explore" @keydown.enter="explore">
    <div
      v-if="!flags.hideCategories"
      class="marker-categories"
      :style="{
        width: `${1.2 * iconSize}px`,
        height: `${1.2 * iconSize}px`,
        margin: `-${0.1 * iconSize}px -${0.1 * iconSize}px`,
      }"
    >
      <div
        class="marker-categories--row"
      >
        <SVGIcon
          v-if="hasDroneFlight && currentZoom >= audiovisualsZoomThreshold"
          icon="campus-properties/multimedia/drone.svg"
          size="25"
          alt-text="Has drone flight"
        />
        <SVGIcon
          v-if="has360Tour && currentZoom >= audiovisualsZoomThreshold"
          icon="campus-properties/multimedia/tour360.svg"
          size="25"
          alt-text="Has drone flight"
        />
      </div>
      <div
        class="marker-categories--row"
      >
        <PropertyCategoryTile
          v-if="currentZoom >= categoriesZoomThreshold"
          :key="`performance_${school.campus_code}_${Math.random()}}`"
          :campus="school"
          :property-key="CAMPUS_PROPERTIES.PERFORMANCE"
          only-icon
          hide-unknown
        />
        <PropertyCategoryTile
          v-if="currentZoom >= categoriesZoomThreshold"
          :key="`performance_${school.campus_code}_${Math.random()}}`"
          :campus="school"
          :property-key="CAMPUS_PROPERTIES.PAYMENT"
          only-icon
          hide-unknown
        />
      </div>
    </div>
    <!-- Favorites Icon -->
    <Flag
      v-if="currentZoom > 14 && isFavorite"
      icon="list-check"
      background="primary-dark"
      styling="icon-round"

      :style="{
        border: '0px solid var(--white)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -55%)',
      }"
    />
    <div v-if="has360Tour && currentZoom < 14" class="marker-categories__small-marker-text">
      {{ $t('markers.360') }}
    </div>
    <!-- !!! REMINDER -->
    <!-- hidden inner circle in marker icon -->
    <MarkerRenderer
      :key="school.uuid"
      :size="iconSize"
      :as-dot="asDot"
      :selected="selected"
      :highlighted="highlighted"
      :explored-campus="exploredCampus"
      :explored-card="exploredCard"
      :show-inner-circle="showInnerCircle"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import MarkerRenderer from '@/components/atoms/svg/Marker.vue';
import PropertyCategoryTile from '@/components/molecules/tiles/PropertyCategoryTile.vue';
import Flag from '@/components/molecules/flags/Flag.vue';
import trackMixPanel from '@/utils/mixpanel';
import { CAMPUS_PROPERTIES } from '@/constants/category';
import { isEmbedded } from '@/utils/embedded';

export default {
  name: 'MarkerIcon',
  components: {
    MarkerRenderer,
    SVGIcon,
    PropertyCategoryTile,
    Flag,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    currentZoom: {
      type: Number,
      default: 0,
    },
    school: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // This variable parametrizes the size of the marker and everything related to it.
      // The only thing that is not parametrized is the size of the icons around it.
      iconSize: 60,
      CAMPUS_PROPERTIES,
    };
  },
  computed: {
    ...mapGetters({
      isGuest: 'authentication/isGuest',
      exploredCampuses: 'institutions/exploredCampuses',
      exploredSchoolCards: 'institutions/exploredSchoolCards',
      filteredExplorerSchools: 'filters/filteredExplorerSchools',
      favoriteGuestList: 'favorites/favoriteGuestList',
      // schoolsVacancies: 'institutions/schoolsVacancies',
      // navbarVacanciesFilter: 'filters/navbarVacanciesFilter',
      flags: 'explorer/flags',
    }),
    highlighted() {
      return this.filteredExplorerSchools.includes(this.school.uuid);
    },
    dotZoomThreshold() {
      return isEmbedded() && this.highlighted ? 0 : 14;
    },
    asDot() {
      return this.currentZoom <= this.dotZoomThreshold || (!this.highlighted && this.flags.shrinkUnfiltered);
    },
    /**
     * Categories are only shown when the zoom is greater than this threshold.
     */
    categoriesZoomThreshold() {
      // 17
      if (isEmbedded()) {
        return this.highlighted ? 0 : Infinity;
      }
      return 17;
    },
    /**
     * Audiovisuals are only shown when the zoom is greater than this threshold.
     */
    audiovisualsZoomThreshold() {
      return isEmbedded() ? Infinity : 15;
    },
    has360Tour() {
      return this.school.multimedia_options?.has_tour;
    },
    hasDroneFlight() {
      return this.school.multimedia_options?.has_drone_flight;
    },
    exploredCampus() {
      return this.exploredCampuses.includes(this.school.uuid);
    },
    exploredCard() {
      return this.exploredSchoolCards.includes(this.school.uuid);
    },
    showInnerCircle() {
      return 0; // Disabled in New Haven
      // const year = Array.from(this.navbarVacanciesFilter)[0];
      // const campusCode = this.school.campus_code;

      // if (year && !this.flags.hideVacancies) {
      //   const vacanciesInfo = this.schoolsVacancies?.[year]?.[campusCode];

      //   return vacanciesInfo !== undefined ? vacanciesInfo : -1;
      // }
      // return -1;
    },
    isFavorite() {
      // TODO: Refactor this. I think the whole favorites logic is very inefficient.
      if (this.isGuest) {
        const isFavorite = this.favoriteGuestList.find((favCampus) => favCampus.uuid === this.school.uuid);
        return !!isFavorite;
      }
      return this.school.isFavorite;
    },
  },
  watch: {
    highlighted: {
      handler(val) {
        this.raiseNode(val);
      },
      immediate: true,
    },
    selected: {
      handler(val) {
        this.raiseNode(val);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      exploreSchoolCard: 'institutions/exploreSchoolCard',
      setShowMapCard: 'institutions/setShowMapCard',
      setShowCard: 'explorer/setShowCard',
      sendGeneralExploredCampus: 'institutions/sendGeneralExploredCampus',
    }),
    raiseNode(raise) {
      // FIXME: This is a workaround to raise the marker when it is highlighted or selected.
      // It does not work when the marker is loaded before other markers that are not highlighted or selected.
      // This is because the markers are loaded in the order they are in the array, so if the highlighted marker
      // is loaded before the other markers, it will be behind them.
      if (raise) {
        this.$nextTick(() => {
          const container = this.$el.parentNode;
          container.parentNode.appendChild(container);
        });
      }
    },
    explore() {
      trackMixPanel('click_school_pin', {
        school_id: this.school.uuid,
        campus_code: this.school.campus_code,
        institution_code: this.school.institution_code,
        name: this.school.campus_name,
      });

      this.setShowCard({ show: true });
      this.sendGeneralExploredCampus({ campusCode: this.school.campus_code, campusUuid: this.school.uuid, event: 'click_campus_pin' });

      if (!this.exploredSchoolCards.includes(this.school.uuid)) {
        this.setShowMapCard(this.school.uuid);
        this.exploreSchoolCard({ campus: this.school });
      }
    },
  },
};
</script>
