var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"student-menu",class:{
      'student-menu--mobile': _vm.mobile,
    }},[_c('div',{staticClass:"student-menu__div__main"},[((!_vm.currentStudent || Object.keys(_vm.currentStudent).length === 0) && _vm.studentList.length === 0)?_c('button',{directives:[{name:"t",rawName:"v-t",value:(_vm.isGuest ? 'header.guest' : 'desktop.header.add-student'),expression:"isGuest ? 'header.guest' : 'desktop.header.add-student'"}],staticClass:"student-menu__div__main__select-guest",class:{ 'student-menu__div__main__select-guest--mobile': _vm.tablet },attrs:{"id":"student-select","type":"button","height":"21","color":"#2b5bff"},on:{"click":function($event){_vm.isGuest ? _vm.goToLogin() : _vm.addStudent()}}}):_vm._e(),(!_vm.isGuest && !_vm.mobile && _vm.studentList.length > 0)?_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isGuest),expression:"!isGuest"}],staticClass:"student-menu__div__main__select ml-2 mr-2",attrs:{"id":"student-select","hide-details":"","items":_vm.allMainFilters,"value":_vm.getMainFilter(),"item-text":"name","item-value":"uuid","solo":"","dense":"","item-color":"#1E0C61","menu-props":{ bottom: true, offsetY: true, value: _vm.showSelection },"append-icon":"$expand"},on:{"change":_vm.changeCurrentStudent,"click":function($event){return _vm.expandSelect()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"student-menu__div__main__select--text"},[_vm._v(" "+_vm._s(_vm.setItemName(item))+" ")])]}},{key:"item",fn:function({ item }){return [(!item.lg)?_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"width":"20","src":require('@/assets/icons/face-primary.svg'),"alt":"Icono tipo usuario"}}):_vm._e(),_c('span',{staticStyle:{"color":"#1E0C61","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.setItemName(item))+" ")])]}}],null,false,475153864)}):_vm._e(),_c('div',{staticClass:"student-menu__filter ml-2 mr-2",class:{
          'student-menu__filter--guest': _vm.isGuest,
          'student-menu__filter--mobile': _vm.mobile,
        }},[(!_vm.showMobileSearchBar)?_c('EditMainInfoBar',{attrs:{"filter-set":_vm.filterSet},on:{"showEditLocation":function($event){return _vm.$emit('showEditLocation')},"showEditGrade":function($event){return _vm.$emit('showEditGrade')}}}):_vm._e()],1),(!_vm.mobile && !_vm.hideVacancies && false)?_c('div',{staticClass:"student-menu__div__main__vacancies"},[_c('v-select',{staticClass:"student-menu__div__main ml-2 mr-2",attrs:{"menu-props":{ bottom: true, offsetY: true },"solo":"","dense":"","item-color":"#1E0C61","append-icon":"$expand","hide-details":"","items":_vm.vacanciesPerYear},on:{"change":_vm.setMapVacanciesFilter},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"width":"20","src":require('@/assets/icons/face-primary.svg'),"alt":"Icono tipo usuario"}}),_c('span',{staticClass:"student-menu__div__main__select--text"},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,644851923),model:{value:(_vm.initialYear),callback:function ($$v) {_vm.initialYear=$$v},expression:"initialYear"}})],1):_vm._e()],1),_c('div',{staticClass:"student-menu__div__main"},[_c('button',{staticClass:"student-menu__div__more",class:{
          'student-menu__div__more--selected': _vm.showFilters,
          'student-menu__div__more--mobile': _vm.mobile,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.openFilters()}}},[_c('SvgIcon',{staticStyle:{"margin-right":"12px"},attrs:{"icon":_vm.getFilterIcons,"size":'20'}}),_vm._v(" "+_vm._s(_vm.$t('header.filters'))+" "),(_vm.publicActiveFiltersCount > 0)?_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s('( ' + _vm.publicActiveFiltersCount + ' )')+" ")]):_vm._e()],1),(!_vm.mobile || _vm.showMobileSearchBar)?_c('CampusSearchBar',{staticStyle:{"margin-right":"8px"},attrs:{"mobile-width":_vm.showMobileSearchBar}}):_vm._e(),(_vm.mobile)?_c('v-icon',{staticClass:"ml-2",attrs:{"medium":"","color":_vm.showMobileSearchBar ? 'red' : 'white'},on:{"click":_vm.showMobileSearch}},[_vm._v(" mdi-magnify ")]):_vm._e()],1)]),_c('v-dialog',{attrs:{"content-class":!_vm.mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'},model:{value:(_vm.showStudentRegisterModal),callback:function ($$v) {_vm.showStudentRegisterModal=$$v},expression:"showStudentRegisterModal"}},[(_vm.studentRegisterStep === 1)?_c('Identification',{attrs:{"in-student-flow":"","no-stepper":"","is-in-map":""},on:{"backStep":function($event){_vm.showStudentRegisterModal = false},"nextStep":_vm.setStudentIdInfo}}):_vm._e(),(_vm.studentRegisterStep === 2)?_c('LocationFlow',{attrs:{"select-step":1,"back":""},on:{"backStep":function($event){_vm.studentRegisterStep = 1},"closeModal":function($event){_vm.showStudentRegisterModal = false}}}):_vm._e(),(_vm.studentRegisterStep === 4)?_c('SelectGrades',{attrs:{"in-student-flow":"","is-in-map":""},on:{"backStep":function($event){_vm.studentRegisterStep = 3},"sendGrade":_vm.sendGrade}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }