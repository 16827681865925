<template>
  <div class="fill-height">
    <div
      class="d-flex fill-height fill-width"
      style="position: relative; overflow-y: hidden"
      :style="{
        'pointer-events': interactive ? 'auto' : 'none',
      }"
    >
      <GmapMap
        v-show="lat && lng"
        ref="mapRef"
        :center="mapCenter"
        :zoom="zoom"
        :class="mobile ? 'vue-map-container-mobile' : 'vue-map-container'"
        :options="{
          disableDefaultUI: true,
          clickableIcons: false,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          styles: mapStyle,
          gestureHandling: 'greedy',
          zoomControl: mobile && interactive,
          zoomControlOptions: {
            position: google?.maps?.ControlPosition.LEFT_BOTTOM,
          },
        }"
        @idle="onDrag"
        @zoom_changed="closeDialogSchool"
      >
        <GmapCircle
          v-if="distanceCircleConfiguration.show && (!isGuest || isEmbedded())"
          :center="homeLocation"
          visible
          :radius="distanceCircleConfiguration.radius"
          :options="styleCircleShape"
        />
        <div v-if="schools.length > 0">
          <!--
            TODO: In order to be able to correctly 'raise' a certain marker, the GmapCustomMarker and MarkerIcon
            need to be in the same component. This is because the GmapCustomMarker component is the one
            that actually renders the marker, and the MarkerIcon is the one that has the logic to 'raise'
            the marker.
          -->
          <GmapCustomMarker
            v-for="(school, i) in schools"
            :id="`id-markermap-${school.uuid}`"
            :key="i"
            :marker="{
              lat: school.location.lat,
              lng: school.location.lon,
            }"
            alignment="center"
          >
            <MarkerIcon
              :ref="`school-${school.uuid}`"
              :selected="currentCampus.uuid === school.uuid && showCard"
              :current-zoom="currentZoom"
              :school="school"
              @click.native="setCurrentSchool(school)"
              @keydown.enter="setCurrentSchool(school)"
            />
          </GmapCustomMarker>
        </div>
        <GmapCustomMarker
          v-if="(!isGuest || isEmbedded())"
          :marker="homeLocation"
          alignment="top"
        >
          <img src="@/assets/markers/home.svg" class="home-icon" alt="" />
        </GmapCustomMarker>
        <div v-if="false">
          <GmapCustomMarker
            v-for="(municipality, i) in municipalities"
            :key="i"
            :marker="mobileSchoolsMarkers(municipality)"
          >
            <div class="d-flex">
              <img
                :src="require('@/assets/iconsNewHaven/icons/schools.svg')"
                alt="Instituciones"
              />
              <p class="mr-1" style="color: white">
                {{ getSchoolCount(municipality) }}
              </p>
            </div>
          </GmapCustomMarker>
        </div>
        <!--COMUNAS-->

        <div>
          <gmap-polygon
            v-for="(shape, i) in regionShapes"
            :key="`polygon-${i}`"
            ref="google_map_polygon"
            :paths="shape"
            :options="polygon.polygonOptions"
            @click="currentZoom < 14 ? polygonMouseClick(i, 14) : ''"
            @mouseover="currentZoom < 14 ? changePolygonMouseOver(i, 0) : ''"
            @focus="currentZoom < 14 ? changePolygonMouseOver(i, 0) : ''"
            @mouseout="changePolygonMouseOut(i)"
            @blur="changePolygonMouseOut(i)"
          />
        </div>
      </GmapMap>
      <div v-if="mobile && !isEmbedded()" class="edit-info-mobile-wrapper">
        <EditInfoMobileBar
          @showEditLocation="setLocationModal()"
          @showEditGrade="setGradeModal()"
        />
      </div>
      <section
        v-if="!isEmbedded()"
      >
        <!-- campus card -->
        <template v-if="showCard && hasCurrentCampus">
          <Card class="marker-map-card slide-top" :campus="currentCampus" @close-card="closeCard()" />
        </template>
        <div
          class="d-flex justify-center fill-width"
          style="position: absolute; bottom: 0px; left: 0px; pointer-events: none"
          :class="{ 'school-card-map-div': mobile }"
        >
          <div class="white-label">
            <img :width="mobile ? 70 : 90" src="@/assets/iconsNewHaven/newhaven-logo.svg" alt="new haven" />
            <img
              :width="mobile ? 200 : 220"
              class="ml-5"
              src="@/assets/iconsNewHaven/newhaven-label.svg"
              alt="new haven"
            />
          </div>
        </div>
        <div
          v-if="showDistrictCard && currentZoom < 14"
          class="district-map-card"
          :style="mobile ? 'bottom: 75px;' : 'bottom: 30px;'"
        >
          <DistrictMapCard :zoom="currentZoom" :name="currentDistrictName" />
        </div>
        <div v-if="!mobile">
          <div
            class="institutions-info institutions-info--left"
            :class="{
              'institutions-info--mobile': mobile,
              'institutions-info--guest': isGuest,
            }"
          >
            <SelectLeftBar v-if="exitsInfoGuest || !isGuest" />
          </div>
          <div
            class="institutions-info"
            :class="{
              'institutions-info--mobile': mobile,
              'institutions-info--guest': isGuest,
            }"
          >
            <SelectRightBar
              v-if="exitsInfoGuest || !isGuest"
              :map="map"
            />
          </div>
        </div>
        <StudentMenu
          v-if="!mobile && (exitsInfoGuest || !isGuest) && !isEmbedded()"
          @showEditLocation="setLocationModal()"
          @showEditGrade="setGradeModal()"
          @showFilterModal="setFilters()"
        />
      </section>
    </div>
    <div
      v-if="!isEmbedded()"
      class="side-controls"
      :class="{
        'side-controls--mobile': mobile,
      }"
    >
      <v-btn
        v-if="mobile"
        depressed
        class="side-controls--button"
        :class="{ 'side-controls--button--mobile': mobile }"
        type="button"
        @click="setFilters()"
      >
        <img :src="require('@/assets/icons/' + getFilterIcons)" width="27px" alt="location" />
      </v-btn>
      <v-btn
        depressed
        class="side-controls--button"
        :class="{ 'side-controls--button--mobile': mobile }"
        :loading="loading"
        :style="mobile ? '' : 'margin-bottom: 110px'"
        type="button"
        @click="currentLocation"
      >
        <img src="@/assets/icons/my_location.svg" width="27px" alt="location" />
      </v-btn>
    </div>

    <v-dialog
      v-if="showEditLocation"
      v-model="showEditLocation"
      :content-class="!mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'"
      style="height: 100vh !important"
      @click:outside="closeModals()"
    >
      <LocationFlow
        :select-step="1"
        @closeModal="closeModals()"
      />
    </v-dialog>
    <v-dialog
      v-if="showEditGrade"
      v-model="showEditGrade"
      :content-class="!mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'"
      style="height: 100vh !important"
      @click:outside="closeModals()"
    >
      <GradeFlow
        v-if="showEditGrade"
        @closeModal="closeModals()"
      />
    </v-dialog>
    <v-dialog
      v-if="!isGuest"
      v-model="showSystemModals"
      persistent
      :content-class="mobile ? 'system-dialogs-mobile' : 'system-dialogs'"
      max-width="400"
    >
      <SystemModals
        :no-priority-warn="noPriorityWarn"
        :modal-index="systemModalIndex"
        :lg-data-warning="lgWarning"
        @closeDialog="showSystemModals = false"
      />
    </v-dialog>
    <v-dialog
      v-model="showFilter"
      :fullscreen="mobile"
      :content-class="mobile ? '' : 'filter-modal'"
      @click:outside="closeFilter"
    >
      <SchoolsFilter :context="filtersContext" @ready="closeFilter" />
    </v-dialog>
  </div>
</template>
<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { mapActions, mapGetters } from 'vuex';
import MarkerIcon from '@/components/explorer/general/MarkerIcon.vue';
import StudentMenu from '@/components/explorer/map/StudentMenu.vue';
// import MapDisclaimer from './MapDisclaimer.vue';
import ElementaryZones from '@/assets/ElementaryZones.json';
import HighschoolZones from '@/assets/HighschoolZones.json';
import SchoolsFilter from '@/components/explorer/map/SchoolsFilter.vue';
import EditInfoMobileBar from '@/components/molecules/bars/EditInfoMobileBar.vue';
import Card from '@/components/organisms/campus/information/Card.vue';
import GradeFlow from '@/components/templates/GradeFlow.vue';
import LocationFlow from '@/components/templates/LocationFlow.vue';
import SystemModals from '@/components/templates/SystemModals.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import {
  TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS,
  TETHER_FILTER_KEYS as FILTER_KEYS,
} from '@/constants/filters';
import trackMixPanel from '@/utils/mixpanel';
import { isEmbedded } from '@/utils/embedded';
import mapStyle from '../../../assets/mapStyle.json';
import DistrictMapCard from './DistrictCard.vue';
import SelectLeftBar from './SelectLeftBar.vue';
import SelectRightBar from './SelectRightBar.vue';

export default {
  name: 'MarkerMap',
  components: {
    LocationFlow,
    GmapCustomMarker,
    // MapDisclaimer,
    MarkerIcon,
    StudentMenu,
    SelectLeftBar,
    SelectRightBar,
    DistrictMapCard,
    // MapShapes,
    SystemModals,
    SchoolsFilter,
    EditInfoMobileBar,
    GradeFlow,
    Card,
  },
  data() {
    return {
      currentCampus: {},
      showEditLocation: false,
      showEditGrade: false,
      zoom: 15,
      lat: null,
      lng: null,
      google: {},
      geocoder: null,
      mapStyle,
      map: null,
      originalCenter: null,
      currentMapCenter: null,
      // Timer to wait for the user to stop dragging or zooming the map
      // So we don't overload the server with requests
      timers: {
        drag: {
          timer: null,
          delay: 0,
        },
        zoom: {
          timer: null,
          delay: 500,
        },
      },
      minZoom: 14,
      showFilter: false,
      infoMarkerCenterLat: '',
      infoMarkerCenterLng: '',
      maploaded: false,
      isMapMounted: false,
      bounds: null,
      regionShapes: null,
      showDistrictCard: false,
      currentDistrictName: 'null',
      districtInfo: [],
      districtJSONS: [],
      polygon: {
        polygonNames: [],
        polygonShapes: [],
        polygonOptions: {
          strokeColor: '#61f7c1',
          fillOpacity: 0.0,
          zIndex: 2,
        },
        polygonOptions2: {
          strokeColor: '#ffffff',
          fillOpacity: 0.3,
          fillColor: '#ffffff',
          zIndex: 1000,
        },
      },
      showSystemModals: false,
      systemModalIndex: 0,
      noPriorityWarn: false,
      lgWarning: false,
      // 1 elementary, 2 highschool
      schoolType: false,
      showFilters: false,
      filtersContext: FILTER_CONTEXTS.EXPLORER,
      styleCircleShape: {
        strokeColor: 'rgba(255, 255, 255, 0.7)',
        strokeWeight: 2,
        fillColor: 'rgba(255, 255, 255, 0.9)',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      exitsInfoGuest: 'authentication/exitsInfoGuest',
      mapCenter: 'explorer/mapCenter',
      showCard: 'explorer/showCard',
      schools: 'institutions/schools',
      homeLocation: 'authentication/homeLocation',
      isGuest: 'authentication/isGuest',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      locationFromUrl: 'institutions/locationFromUrl',
      campusOfSearchBar: 'institutions/campusOfSearchBar',
      explorerFlags: 'explorer/flags',
      distanceCircleConfiguration: 'explorer/distanceCircleConfiguration',
      cornerBounds: 'explorer/mapCornerBounds',
      forceMapZoom: 'explorer/mapZoom',
      navbarVacanciesFilter: 'filters/navbarVacanciesFilter',
      activeGrades: 'authentication/activeGrades',
      hideVacancies: 'explorer/hideVacancies',
    }),
    hasCurrentCampus() {
      return Object.keys(this.currentCampus).length > 0;
    },
    currentZoom() {
      return this.map ? this.map.getZoom() : null;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getFilterIcons() {
      if (this.showFilters) {
        return 'filters-white.svg';
      }
      return 'filters.svg';
    },
    interactive() {
      return !isEmbedded() || this.explorerFlags.interactiveEmbed;
    },
  },
  watch: {
    campusOfSearchBar: {
      handler() {
        this.currentCampus = this.campusOfSearchBar;
      },
    },
    currentMapCenter() {
      const calculateOffset = (zoom) => Math.exp(5.6333 - 0.7154 * zoom) * 1.5;
      const offset = calculateOffset(this.map.getZoom());
      const lat = this.map.getCenter().lat() - offset;
      const lng = this.map.getCenter().lng();
      this.infoMarkerCenterLat = `${lat}`;
      this.infoMarkerCenterLng = `${lng}`;
      clearTimeout(this.timers.drag.timer);
      this.timers.drag.timer = setTimeout(this.recalculateCorners, this.timers.drag.delay);
    },
    currentZoom: {
      handler(newZoom, oldZoom) {
        if (newZoom) {
          if (newZoom === 13 && this.mobile) {
            this.drawMapShapes();
            this.info({
              content: this.$t('map.info.zoom_out'),
              timeout: 5000,
            });
          }
          if (newZoom > this.minZoom) {
            this.reset();
          }
          if (newZoom > oldZoom) {
            clearTimeout(this.timers.zoom.timer);
            this.timers.zoom.timer = setTimeout(() => this.recalculateCorners(true), this.timers.zoom.delay);
          }
        }
      },
    },
    activeGrades: {
      handler({ grades }) {
        this.setShapefile(grades);
      },
      immediate: true,
    },
    forceMapZoom: {
      handler() {
        if (this.map) {
          this.map.setZoom(this.forceMapZoom);
        }
      },
      immediate: true,
    },
  },
  activated() {
    if (this.isMapMounted) {
      const { lat, lng } = this.homeLocation;
      this.navigateTo({ lat, lng });
      this.lat = lat;
      this.lng = lng;
    }
  },
  async mounted() {
    await this.loadShapes();
    this.loadAllMapShapes();
    this.setInRegisterFlow(false);
    this.ensureVacanciesFilter();
    this.setShowCard({ show: false });
    this.currentCampus = this.campusOfSearchBar;

    if (this.legalGuardianAddress !== null && this.legalGuardianAddress !== undefined) {
      if (
        this.legalGuardianAddress[0].address_lat === null
        && this.legalGuardianAddress[0].address_lon === null
      ) {
        this.showEditLocation = true;
      }
    }
    this.$gmapApiPromiseLazy()
      .then((google) => {
        this.google = google;
        this.geocoder = new google.maps.Geocoder();
        // return this.geocode(this.geocoderData);
      })
      .then(() => {
        const { lat, lng } = this.locationFromUrl ? this.mapCenter : this.homeLocation;
        if (this.locationFromUrl) {
          this.setLocationFromUrl(false);
        } else {
          this.navigateTo({ lat, lng });
        }

        this.lat = lat;
        this.lng = lng;
      })
      .finally(() => {
        this.isMapMounted = true;
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.map = map;
          // When the map is loaded, set the zoom and the bounds which will trigger the retrieveCampuses action
          this.google.maps.event.addListenerOnce(map, 'idle', () => {
            map.setZoom(this.forceMapZoom || this.zoom);
            this.setMapCorners(map);
            /**
             * TODO #1 https://github.com/ConsiliumBots/cb-explorer-chile-back/projects/1
             * Set circle in non Artificial legalguardian
             */
            // this.drawMapCircle(this.distanceRadius);
          });
          setTimeout(() => {
            this.maploaded = true;
          }, 2000);
        });
      });
  },
  methods: {
    ...mapActions({
      navigateTo: 'explorer/navigateTo',
      setCornerBounds: 'explorer/setCornerBounds',
      setShowCard: 'explorer/setShowCard',
      retrieveCampuses: 'institutions/retrieveCampuses',
      info: 'utils/infoWithTimeout',
      reset: 'utils/resetSnackbar',
      setInRegisterFlow: 'userRegister/setInRegisterFlow',
      setLocationFromUrl: 'institutions/setLocationFromUrl',
      setFilter: 'filters/setFilter',
      retrieveVacancies: 'institutions/retrieveVacancies',
    }),
    updateVacancies() {
      const yearSelected = Array.from(this.navbarVacanciesFilter);
      const { grades } = this.activeGrades;
      this.retrieveVacancies({
        year: yearSelected[0],
        grade: grades?.length > 0 ? grades : null,
        campuses: this.schools.map((school) => school.campus_code),
      });
    },
    setShapefile(gradesId) {
      // load highschool Zones only ids 12, 13, 14, 15 (highschool)
      const loadHighSchoolShapes = gradesId.length === 0
        ? false : gradesId.every((grade) => grade >= 12 && grade <= 15);

      if (loadHighSchoolShapes) {
        this.schoolType = 2;
        this.districtJSONS = [HighschoolZones];
      } else {
        this.schoolType = 1;
        this.districtJSONS = [ElementaryZones];
      }
      this.drawMapShapes();
    },
    async loadShapes() {
      try {
        const dataOfDistricts = await import(`@/assets/${TENANT_CONFIGURATION.DEFAULTS.SHAPE.DISTRICT}`) ?? [];
        const { features: dataOfPolygonShapes = [] } = await import(`@/assets/${TENANT_CONFIGURATION.DEFAULTS.SHAPE.POLYGON}`) ?? [];
        this.districtJSONS = [dataOfDistricts];
        this.polygon.polygonShapes = dataOfPolygonShapes;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, 'error load shapes');
      }

      this.drawMapShapes();
    },
    ensureVacanciesFilter() {
      if (this.navbarVacanciesFilter.size === 0) {
        this.setFilter({
          context: FILTER_CONTEXTS.NAVBAR,
          key: FILTER_KEYS.HAS_VACANCIES,
          value: [new Date().getFullYear()],
          from: 'marker map',
        });
      }
    },
    closeCard() {
      this.setShowCard({ show: false });
    },
    setCurrentSchool(campus) {
      this.currentCampus = campus;
    },
    closeModals() {
      this.showEditLocation = false;
      this.showEditGrade = false;
    },
    setMapCorners(map) {
      try {
        const bounds = map.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        const cornerBounds = [
          { lat: northEast.lat(), lng: southWest.lng() }, // North West
          { lat: northEast.lat(), lng: northEast.lng() }, // North East
          { lat: southWest.lat(), lng: northEast.lng() }, // South East
          { lat: southWest.lat(), lng: southWest.lng() }, // South West
        ];
        // This is a helper url to debug the bounds that are being set
        // console.log(`https://linestrings.com/bbox/#${northEast.lng()},${southWest.lat()},${southWest.lng()},${northEast.lat()}`);

        this.setCornerBounds({ cornerBounds });
        this.bounds = cornerBounds;
        this.originalCenter = map.getCenter();
        this.currentMapCenter = map.getCenter();
        this.retrieveCampuses({
          bounds: cornerBounds,
        }).then(() => {
          if (!this.hideVacancies) this.updateVacancies();
        });
      } catch {
        this.retrieveCampuses({});
      }
    },
    setFilters() {
      if (this.showFilter) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
      return this.showFilter;
    },
    polygonMouseClick(i, zoom) {
      if (zoom === 14) {
        const districtInfo = this.polygon.polygonNames[i];
        const districtCenterLocation = {
          lat: districtInfo.lat,
          lng: districtInfo.lng,
        };
        this.$refs.mapRef.$mapObject.setCenter(districtCenterLocation);
      }
      if (zoom === 11) {
        this.$refs.mapRef.$mapObject.setCenter(
          this.getCenter(this.$refs.google_map_polygon[i].$polygonObject),
        );
      }
      this.changePolygonMouseOut(i);
      this.$refs.mapRef.$mapObject.setZoom(zoom);
    },
    changePolygonMouseOver(i) {
      this.showDistrictCard = true;
      this.$refs.google_map_polygon[i].$polygonObject.setOptions(this.polygon.polygonOptions2);
      this.currentDistrictName = this.polygon.polygonNames[i].name;
    },
    changePolygonMouseOut(i) {
      // SHOW CARD
      this.showDistrictCard = false;
      this.$refs.google_map_polygon[i].$polygonObject.setOptions(this.polygon.polygonOptions);
    },
    closeDialogSchool() {
      this.setShowCard({ show: false });
    },
    /* Based on
      https://github.com/tparkin/Google-Maps-Point-in-Polygon/blob/master/maps.google.polygon.containsLatLng.js */
    getBounds(polygon) {
      // eslint-disable-next-line no-undef
      const bounds = new google.maps.LatLngBounds();
      const paths = polygon.getPaths();
      let path;
      let p;
      let i;
      for (p = 0; p < paths.getLength(); p += 1) {
        path = paths.getAt(p);
        for (i = 0; i < path.getLength(); i += 1) {
          bounds.extend(path.getAt(i));
        }
      }
      return bounds;
    },
    getCenter(polygon) {
      return this.getBounds(polygon).getCenter();
    },
    /**
     * This method is called whenever a user interaction with the map is detected (dragging or zooming)
     * It checks if the map has been dragged or zoomed in enough to recalculate the bounds of the map,
     * and if so, it sets the new bounds which will trigger the retrieveCampuses action
     * @param {Boolean} zoomedIn - If the map has been zoomed in, we need to recalculate the bounds even if the map
     * hasn't been dragged
     */
    recalculateCorners(zoomedIn) {
      if (this.shouldRecalculateCorners(zoomedIn)) {
        this.setMapCorners(this.map);
      }
    },
    shouldRecalculateCorners(zoomedIn) {
      if (zoomedIn || !this.cornerBounds) {
        return true;
      }
      const northEast = this.cornerBounds[1];
      const southWest = this.cornerBounds[3];
      const bounds = this.map.getBounds();
      // Check if on dragging, bounds with offset touches the border of map
      const touchLeftBorder = bounds.getSouthWest().lng() < southWest.lng;
      const touchRightBorder = bounds.getNorthEast().lng() > northEast.lng;
      const touchTopBorder = bounds.getNorthEast().lat() > northEast.lat;
      const touchBottomBorder = bounds.getSouthWest().lat() < southWest.lat;
      const should = (
        (touchLeftBorder || touchRightBorder || touchTopBorder || touchBottomBorder)
        && this.map.getZoom() >= this.minZoom
      );
      return should;
    },
    onDrag() {
      this.currentMapCenter = this.map.getCenter();
    },
    loadAllMapShapes() {
      this.drawMapShapes();
    },
    drawMapShapes() {
      this.polygon.polygonNames = [];
      // FIXME: Creo que esto es CRÍTICO para refactoring pronto.
      const shapeList = [];
      for (let d = 0; d < this.districtJSONS.length; d += 1) {
        const jsonRegion = this.districtJSONS[d];
        for (let i = 0; i < jsonRegion.features.length; i += 1) {
          const shapeStructure = [[]];
          const poligono = jsonRegion.features[i];
          const polygonName = this.schoolType === 1 ? poligono.properties.elem_name : poligono.properties.high_name;
          const schoolCode = this.schoolType === 1 ? poligono.properties.elem_code : poligono.properties.high_code;
          if (poligono.geometry.type === 'Polygon') {
            for (let j = 0; j < poligono.geometry.coordinates[0].length; j += 1) {
              // Dado que el archivo de shapes viene "malo", es necesario procesar los datos
              const dott = {
                lng: poligono.geometry.coordinates[0][j][0],
                lat: poligono.geometry.coordinates[0][j][1],
              };
              shapeStructure[0].push(dott);
            }
            shapeList.push(shapeStructure);
            if (polygonName) {
              this.polygon.polygonNames.push({
                name: polygonName,
                lat: poligono.properties.latitud,
                lng: poligono.properties.longitud,
              });
            }
            this.districtInfo.push({
              name: polygonName,
              nationalCode: schoolCode,
            });
          } else if (poligono.geometry.type === 'MultiPolygon') {
            for (let j = 0; j < poligono.geometry.coordinates.length; j += 1) {
              const multiShapeStructure = [[]];

              for (let k = 0; k < poligono.geometry.coordinates[j][0].length; k += 1) {
                const dott = {
                  lng: poligono.geometry.coordinates[j][0][k][0],
                  lat: poligono.geometry.coordinates[j][0][k][1],
                  name: poligono.properties.name,
                };
                multiShapeStructure[0].push(dott);
              }

              // Dado que el archivo de shapes viene "malo", es necesario procesar los datos
              shapeList.push(multiShapeStructure);
              if (this.schoolType === 1) {
                this.polygon.polygonNames.push({
                  name: polygonName,
                  lat: poligono.properties.latitud,
                  lng: poligono.properties.longitud,
                });
              } else {
                this.polygon.polygonNames.push({
                  name: polygonName,
                  lat: poligono.properties.latitud,
                  lng: poligono.properties.longitud,
                });
              }
              // multiShapeList.push(multiShapeStructure)
            }
          }
        }
      }
      this.regionShapes = shapeList;
    },
    closeFilter() {
      this.showFilter = false;
    },
    async currentLocation() {
      this.loading = true;
      await this.$getLocation({})
        .then((coordinates) => {
          this.lat = coordinates.lat;
          this.lng = coordinates.lng;
          const { lat, lng } = this;
          this.navigateTo({ lat, lng });
          this.map.setCenter({ lat, lng });
          this.currentMapCenter = this.map.getCenter();
          trackMixPanel('map_center_map');
          this.loading = false;
        });
    },
    setLocationModal() {
      if (!this.showEditLocation) {
        this.showEditLocation = true;
        return this.showEditLocation;
      }
      this.showEditLocation = false;
      return this.showEditLocation;
    },
    setGradeModal() {
      if (!this.showEditGrade) {
        this.showEditGrade = true;
        return this.showEditGrade;
      }
      this.showEditGrade = false;
      return this.showEditGrade;
    },
    isEmbedded,
  },
};
</script>
<style scoped>
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%);
  background: rgb(255, 255, 255, 0.6);
  padding: 0;
}
.v-application .primary--text {
  color: transparent !important;
}

.marker-map-card {
  position: absolute;
  bottom: 44px;
  right: 0;
  left: 0;
  margin: auto;
}
</style>
