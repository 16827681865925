<template>
  <section class="app-view">
    <NavbarApplication class="nav-app" />
    <div v-if="showViewApplication" :class="mobile ? '' : 'wrap-application'">
      <div :class="mobile ? 'app-info--mobile' : 'app-info'">
        <div v-if="mobile" class="read-button">
          <div class="app-info__title">
            {{ $t('application.title-palmira') }}
          </div>
          <div class="app-info__subtitle">
            {{ $t('application.description') }}
          </div>
          <div v-show="readMore" class="app-info__subtitle">
            {{ $t('application.description-2') }}
          </div>
          <div v-show="readMore" class="app-info__subtitle">
            {{ $t('application.description-3') }}
          </div>
          <v-btn tile @click="readMore = !readMore">
            <v-icon v-if="!readMore" left>
              mdi-plus-circle
            </v-icon>
            <v-icon v-else left>
              mdi-minus-circle
            </v-icon>
            <span v-if="readMore">Ver menos</span>
            <span v-else>Ver más</span>
          </v-btn>
        </div>
        <div v-else>
          <div class="app-info__title">
            {{ $t('application.title-palmira') }}
          </div>
          <div class="app-info__subtitle">
            {{ $t('application.description') }}
          </div>
          <div class="app-info__subtitle">
            {{ $t('application.description-2') }}
          </div>
          <div class="app-info__subtitle">
            {{ $t('application.description-3') }}
          </div>
        </div>
      </div>
      <ApplicationList class="app-list" />
    </div>
    <div v-else class="banner-application">
      <picture>
        <img src="@/assets/explorer/application/bg-application-desktop.svg" alt="bg application">
      </picture>
      <div class="banner-application__logo">
        <h1 v-t="'application.title_newhaven'" />
        <img width="200" src="@/assets/explorer/application/newhaven-bg-white.svg" alt="New haven">
      </div>
      <div class="banner-application__information">
        <h1>
          {{ $t('application.banner-newhaven') }}
          <b>{{ $t('application.banner-newhaven_february') }} </b>
          {{ $t('application.banner-newhaven_until') }}
          <b>{{ $t('application.banner-newhaven_march') }} </b>
        </h1>
        <p v-t="'application.banner-newhaven_simulation'" class="mt-10 px-4" />
        <div class="mt-4 banner-application__buttons">
          <button
            v-t="'application.banner-newhaven__apply'"
            :disabled="false"
            type="button"
            @click="toLinkApplication()"
          />
          <button
            v-t="'application.banner-newhaven__apply__simulate'"
            :disabled="false"
            type="button"
            @click="goToPath('simulate')"
            @keydown.enter="goToPath('simulate')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '@/styles/components/application/_application.scss';
// import { mapActions } from 'vuex';
import ApplicationList from '@/components/explorer/application/ApplicationList.vue';
import NavbarApplication from '@/components/explorer/application/NavbarApplication.vue';

// ,src\styles\components\application\_application.scss
export default {
  name: 'ApplicationLayout',
  // mounted() {
  //   this.retrieveListFavorites();
  // },
  components: {
    NavbarApplication,
    ApplicationList,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.application')}`,
      meta: [],
    };
  },
  data() {
    return {
      readMore: false,
      showViewApplication: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  // methods: {
  //   ...mapActions({
  //     retrieveListFavorites: 'favorites/retrieveListFavorites',
  //   }),
  // },
  methods: {
    toLinkApplication() {
      const linkApplication = 'https://nhps.schoolmint.com/';
      if (linkApplication !== '') {
        window.open(linkApplication, '_blank');
      }
    },
    goToPath(path) {
      if (this.currentRoute !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
