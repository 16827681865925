<template>
  <div
    class="application-selection-modal"
    :class="{ 'application-selection-modal--mobile': mobile }"
  >
    <ModalHeader
      :content-sections="['close', 'title', 'subtitle']"
      :title-text="'user_register.student_selection.simulate.title'"
      :subtitle-text="'user_register.student_selection.simulate.subtitle'"
      center-subtitle
      close-no-margin
      @close="$emit('closeModal')"
      @back="goBack()"
    />
    <section
      class="application-selection-modal--sections"
      :class="{ 'application-selection-modal--sections--mobile': mobile }"
    >
      <ModalContent
        :content-sections="['subtitle', 'selectionButton', 'instructions']"
        :subtitle-text="'user_register.student_selection.simulate.student_title'"
        subtitle-light
        row-instructions
        selection-button-narrow
        selection-button-multi-icon
        :selection-button-click-disabled="isGuest || students.length === 0"
        :instruction-text="'user_register.student_selection.simulate.optional'"
        :selection-button-info="studentsSelectInfo"
        @setSelectionButtonValue="setActiveStudent"
      />
    </section>
    <section
      class="application-selection-modal--sections"
      :class="{ 'application-selection-modal--sections--mobile': mobile }"
    >
      <ModalContent
        :content-sections="['subtitle']"
        :subtitle-text="'user_register.student_selection.simulate.grade_title'"
        subtitle-light
        subtitle-no-margin
      />
      <SelectGrades
        only-grades
        button-grades
        in-student-flow
        button-wrap
        :change-students="studentChange"
        @setGrade="setGradeSelected"
      />
    </section>
    <section
      class="application-selection-modal--sections"
      :class="{ 'application-selection-modal--sections--mobile': mobile }"
    >
      <ModalContent
        :content-sections="['subtitle', 'instructions']"
        :subtitle-text="'user_register.student_selection.simulate.priority_title'"
        subtitle-light
        subtitle-no-margin
        :instruction-text="'user_register.student_selection.simulate.optional'"
        row-instructions
      />
      <section style="margin-top: 20px">
        <section
          class="d-flex"
          style="margin-top: 20px"
          :style=" mobile ? 'margin: 20px 0 20px 0' : 'margin-top: 20px'"
        >
          <SectionSelectors
            :selector-array="firstRowPrioritiesSelector"
            :mobile="mobile"
            @setValue="setSelectedPriority"
          />
        </section>
        <LanguagePriorities
          v-if="selectedSelector === 1"
          in-simulation
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          pre-fill
          style="border-radius: 0px 8px 8px 8px !important;"
          @nextStep="setCompletedPriorities(1)"
        />
        <BrotherInCampus
          v-if="selectedSelector === 2"
          in-simulation
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          pre-fill
          @backStep="selectedSelector = 2"
          @nextStep="setCompletedPriorities(2)"
        />
        <LocationFlow
          v-if="selectedSelector === 3"
          :select-step="1"
          in-simulation
          class="application-selection-modal--sections--priorities"
          :class="{ 'application-selection-modal--sections--priorities--mobile': mobile }"
          @closeModal="setCompletedPriorities(3)"
        />
      </section>
    </section>
    <MainButton
      class=" d-flex fill-width justify-center"
      :is-active="Object.keys(gradeSelected).length > 0"
      :text="'user_register.brother_in_campus.confirm'"
      style="padding: 0 100px; margin-top: 68px;"
      @continue="Object.keys(gradeSelected).length > 0 ? confirm() : null"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MainButton from '@/components/atoms/buttons/MainButton.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import SectionSelectors from '@/components/molecules/sections/SectionSelectors.vue';
import BrotherInCampus from '@/components/organisms/register/BrotherInCampus.vue';
import LanguagePriorities from '@/components/organisms/register/LanguagePriorities.vue';
import SelectGrades from '@/components/shared/SelectGrades.vue';
import LocationFlow from '@/components/templates/LocationFlow.vue';
// import LocationFlow from '@/components/templates/LocationFlow.vue';

export default {
  name: 'ApplicationStudentSelectionModal',
  components: {
    ModalHeader,
    ModalContent,
    SelectGrades,
    SectionSelectors,
    BrotherInCampus,
    LocationFlow,
    LanguagePriorities,
    MainButton,
  },
  data() {
    return {
      studentsSelectInfo: [],
      selectedSelector: 0,
      prioritiesCompleted: [],
      gradeSelected: {},
      studentChange: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      students: 'authentication/students',
      simulateFromPath: 'simulation/simulateFromPath',
      guestSimulationData: 'simulation/guestSimulationData',
      isGuest: 'authentication/isGuest',
    }),
    /* HIDDEN SELECTOR FOR BASE
    FOR ROW SELECTOR
    firstRowPrioritiesSelector() {
      const selector = [
        {
          id: 1,
          label: this.prioritiesCompleted.includes(1)
            ? this.getNativeLanguageLabel() : 'user_register.student_selection.simulate.priority_labels.native',
          completed: this.prioritiesCompleted.includes(1),
          selected: this.selectedSelector === 1,
        },
        {
          id: 2,
          label: 'user_register.student_selection.simulate.priority_labels.brother',
          completed: this.prioritiesCompleted.includes(2),
          selected: this.selectedSelector === 2,
        },
        {
          id: 3,
          label: 'user_register.student_selection.simulate.priority_labels.location',
          completed: this.prioritiesCompleted.includes(3),
          selected: this.selectedSelector === 3,
        },
      ];
      return selector;
    },
    */
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    firstRowPrioritiesSelector() {
      const selector = [
        {
          id: 1,
          label: this.prioritiesCompleted.includes(1)
            ? this.getNativeLanguageLabel() : 'user_register.student_selection.simulate.priority_labels.native',
          completed: this.prioritiesCompleted.includes(1),
          selected: this.selectedSelector === 1,
        },
        {
          id: 2,
          label: 'user_register.student_selection.simulate.priority_labels.brother',
          completed: this.prioritiesCompleted.includes(2),
          selected: this.selectedSelector === 2,
        },
        {
          id: 3,
          label: 'user_register.student_selection.simulate.priority_labels.location',
          completed: this.prioritiesCompleted.includes(3),
          selected: this.selectedSelector === 3,
        },
      ];
      return selector;
    },
    hasLanguages() {
      const { lang: guestSimulationLanguages = [] } = this.guestSimulationData;
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return !!(this.currentStudent.native_languages && this.currentStudent.native_languages.length > 0);
      }
      if (guestSimulationLanguages) {
        return guestSimulationLanguages.length > 0;
      }
      return false;
    },
    hasBrotherInCampus() {
      const { sibling: guestSimulationSiblings = [] } = this.guestSimulationData;
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return !!(this.currentStudent.siblings && this.currentStudent.siblings.length > 0);
      }
      return guestSimulationSiblings.length > 0;
    },
    hasLocation() {
      // TODO: Change when we have the logic needed for detecting default locations.
      // HomeLocation needs to be enhanced to include all information relevant to the location, with something like:
      // {
      //   coordinates: {
      //     lat: 0,
      //     lng: 0,
      //   },
      //   municipalityName: 'string',
      //   source: 'LegalGuardian | CurrentStudent | Guest | Default',
      //  }
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        return !!(this.currentStudent.address && this.currentStudent.address.length > 0);
      }
      const {
        lat: guestSimulationLat,
        long: guestSimulationLng,
        city: guestSimulationCity,
      } = this.guestSimulationData;
      if (guestSimulationLat && guestSimulationLng && guestSimulationCity) {
        return true;
      }
      return false;
    },
    isSomeStudentActive() {
      return this.studentsSelectInfo.find((student) => student.isActive === true);
    },
  },
  watch: {
    currentStudent: {
      handler() {
        this.selectorPriorityPrefill();
      },
      deep: true,
    },
    guestSimulationData: {
      handler() {
        this.selectorPriorityPrefill();
      },
      deep: true,
    },
  },
  mounted() {
    this.getSelectionInfo();
    this.selectorPriorityPrefill();
  },
  methods: {
    ...mapActions({
      setStudentGrades: 'newStudentRegister/setStudentGrades',
      setFastRegisterGrade: 'userRegister/setFastRegisterGrade',
      setCurrentStudent: 'authentication/setCurrentStudent',
      setGuestData: 'authentication/setGuestData',
      setSimulationModalCompleted: 'simulation/setSimulationModalCompleted',
      setIsGuestClickFirstTime: 'authentication/setIsGuestClickFirstTime',
    }),
    selectorPriorityPrefill() {
      this.prioritiesCompleted = [];
      if (this.hasLanguages) {
        this.prioritiesCompleted.push(1);
      }
      if (this.hasBrotherInCampus) {
        this.prioritiesCompleted.push(2);
      }
      if (this.hasLocation) {
        this.prioritiesCompleted.push(3);
      }
    },
    setGradeSelected(grade, stage) {
      this.gradeSelected = { grade, stage };
    },
    setActiveStudent(index) {
      this.gradeSelected = {};
      if (this.studentsSelectInfo[index].isActive) {
        this.studentsSelectInfo[index].isActive = false;
      } else {
        this.studentsSelectInfo[index].isActive = true;
      }
      this.studentsSelectInfo.map((student) => {
        if (student.id !== index) {
          Object.assign(student, { isActive: false });
        }
        return student;
      });
      if (this.isSomeStudentActive) {
        this.setCurrentStudent({ student: this.students[index] }).then(() => {
          this.selectedSelector = 0;
          if (this.studentChange === 2) {
            this.studentChange = 3;
          } else {
            this.studentChange = 2;
          }
        });
      } else {
        this.setCurrentStudent({ student: {} }).then(() => {
          this.gradeSelected = {};
          this.selectedSelector = 0;
          this.prioritiesCompleted = [];
        });
      }
      return this.studentsSelectInfo;
    },
    getSelectionInfo() {
      if (this.isGuest || this.students.length === 0) {
        this.studentsSelectInfo.push(
          {
            label: this.$t('simulation.guest'),
            isActive: true,
            id: 0,
          },
        );
      } else {
        this.students.forEach((student, index) => {
          this.studentsSelectInfo.push(
            {
              label: student.first_name,
              isActive: index === 0,
              id: index,
            },
          );
        });
      }
    },
    setSelectedPriority(selectorId) {
      if (this.selectedSelector === selectorId) {
        this.selectedSelector = 0;
        return this.selectedSelector;
      }
      this.selectedSelector = selectorId;
      return this.selectedSelector;
    },
    setCompletedPriorities(priorityIndex) {
      this.selectorPriorityPrefill();
      this.selectedSelector = priorityIndex + 1;
    },
    confirm() {
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        const gradeOptions = {
          uuid: this.currentStudent.uuid,
          grades: [this.gradeSelected.grade.id],
          stages: [this.gradeSelected.stage.id],
        };
        this.setStudentGrades({ gradeOptions, updateStudent: this.currentStudent.uuid })
          .then(() => {
            this.setSimulationModalCompleted(true);
            this.$emit('closeModal');
          });
      } else if (!this.isGuest) {
        const gradeOptions = {
          grades: [this.gradeSelected.grade.id],
          stages: [this.gradeSelected.stage.id],
        };
        this.setFastRegisterGrade({ gradesData: gradeOptions }).then(() => {
          this.setSimulationModalCompleted(true);
          this.$emit('closeModal');
        });
      } else {
        this.setGuestData({ grades: [this.gradeSelected.grade.id] }).then(() => {
          this.setIsGuestClickFirstTime({ property: 'grade' });
          this.setSimulationModalCompleted(true);
          this.$emit('closeModal');
        });
      }
    },
    goBack() {
      this.$router.push(this.simulateFromPath);
    },
    getNativeLanguageLabel() {
      if (!this.isGuest) {
        const firstNativeLang = this.currentStudent.native_languages[0].nativelanguage.nativelanguage_name;
        const numberOfExtraLangs = `(${this.currentStudent.native_languages.length - 1}+)`;
        if (this.currentStudent.native_languages.length > 1) {
          return `${this.$t('user_register.student_selection.simulate.priority_labels.native')}:
      ${firstNativeLang} ${numberOfExtraLangs}`;
        }
      }
      return `${this.$t('user_register.student_selection.simulate.priority_labels.native')}`;
    },
  },
};
</script>
