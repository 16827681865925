<template>
  <section
    v-if="campus"
    class="campus-card d-flex flex-column"
    :class="{
      'campus-card--mobile': mobile,
    }"
  >
    <!-- Close button -->
    <v-btn v-if="showCloseButton" icon color="#737373" class="campus-card__close" @click="closeCard()">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <!-- Campus header -->
    <div class="campus-card__header">
      <!-- Campus image -->
      <picture class="reveal-animation" style="--d: 0.1s">
        <ImageAtom
          :src="campusInformation.image.src"
          :alt="campusInformation.image.alt"
          is-responsive
        />
      </picture>
      <!-- Campus Information -->
      <div class="pl-3">
        <TitleVerifiedAccount
          :prime="campusInformation.tetherPack"
          :verified="campusInformation.verified"
          :title="campusInformation.name"
          :freemium="campusInformation.freemium"
        />
        <div class="w-full d-flex flex-row">
          <TextAtom
            v-if="campusInformation.description.sector"
            :value="campusInformation.description.sector"
            color="primary-dark"
            font="inter"
            size="default"
            class="text-left reveal-animation"
            style="--d: 0.15s"
          />
          <TextAtom
            v-if="campusInformation.description.showSeparator"
            value="|"
            color="primary-dark"
            font="inter"
            size="default"
            class="text-left reveal-animation mx-2"
            style="--d: 0.15s"
          />
          <TextAtom
            :value="campusInformation.description.grades"
            color="primary-dark"
            font="inter"
            size="default"
            class="text-left reveal-animation"
            style="--d: 0.15s"
          />
        </div>
        <VacancyTile
          v-if="false"
          :text="vacanciesInformation.text"
          :background-color="vacanciesInformation.backgroundColor"
          :color="vacanciesInformation.color"
          :loading="vacanciesLoader"
        />
      </div>
      <!-- Campus flags -->
    </div>
    <template v-if="size === 'large'">
      <hr
        class="reveal-animation"
        style="--d: 0.16s"
      />
      <CampusCategories
        class="reveal-animation"
        style="--d: 0.17s"
        :campus="campus"
      />
      <!-- actions school -->
      <div class="campus-card__actions">
        <button
          v-t="'map.card.show-profile'"
          type="button"
          @click="explore()"
        />
        <div>
          <FavoriteButton :campus="campus" is-large-button />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import FavoriteButton from '@/components/explorer/favorite/FavoriteButton.vue';
import TitleVerifiedAccount from '@/components/molecules/verified_account/TitleVerifiedAccount.vue';
import trackMixPanel from '@/utils/mixpanel';
import { getLocaleProperty } from '@/utils/locale';
import { CAMPUS_SUBSCRIPTION_STATUS } from '@/constants/category';
import VacancyTile from '@/components/molecules/tiles/VacancyTile.vue';
import CampusCategories from './CampusCategories.vue';

export default {
  name: 'CampusCard',
  components: {
    ImageAtom,
    TextAtom,
    FavoriteButton,
    TitleVerifiedAccount,
    CampusCategories,
    VacancyTile,
  },
  props: {
    campus: {
      type: Object,
      default: () => {},
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'large',
      validator(value) {
        return ['small', 'large'].includes(value);
      },
    },
  },
  computed: {
    ...mapGetters({
      schoolsVacancies: 'institutions/schoolsVacancies',
      navbarVacanciesFilter: 'filters/navbarVacanciesFilter',
      vacanciesLoader: 'institutions/vacanciesLoader',
    }),
    campusInformation() {
      return {
        image: this.campusImage,
        name: this.campusName,
        description: this.campusDescription,
        flags: this.campusFlags,
        tetherPack: this.campus?.account_type === CAMPUS_SUBSCRIPTION_STATUS.PREMIUM,
        verified: this.campus?.verified || false,
        freemium: this.campus?.account_type === CAMPUS_SUBSCRIPTION_STATUS.FREEMIUM,
      };
    },
    vacanciesInformation() {
      const year = Array.from(this.navbarVacanciesFilter);
      const yearVacancy = this.schoolsVacancies[year];
      let vacancy;

      if (yearVacancy && yearVacancy[this.campus.campus_code] !== 0) {
        vacancy = (yearVacancy[this.campus.campus_code] > 0) ? 1 : 2;
      } else {
        vacancy = yearVacancy?.[this.campus.campus_code];
      }

      const label = {
        0: {
          text: this.$t('map.card.vacancies.closed'),
          backgroundColor: 'secondary-focus',
          color: 'primary-dark',
        },
        1: {
          text: this.$t('map.card.vacancies.open'),
          backgroundColor: 'primary-medium',
          color: 'white',
        },
        2: {
          text: this.$t('map.card.vacancies.no-info'),
          backgroundColor: 'gray-line',
          color: 'primary-dark',
        },
      };

      if (vacancy === -1 || vacancy === undefined) {
        vacancy = 2;
      }

      return {
        text: label[vacancy].text + year[0],
        backgroundColor: label[vacancy].backgroundColor,
        color: label[vacancy].color,
      };
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    campusImage() {
      const thumbnail = this.campus?.image_thumb?.[0] || {};
      const campusAlt = thumbnail?.image_description || thumbnail.image_name || 'No image';

      return {
        src: this.campus?.image_thumb?.[0]?.image_link || '',
        alt: campusAlt,
      };
    },
    campusName() {
      return this.campus?.campus_short_name || this.campus?.campus_name || '';
    },
    campusDescription() {
      const sector = this.campus?.sector?.sector_name ? getLocaleProperty(this.campus?.sector, 'sector_name') : null;

      const showSeparator = !!(sector && (this.campusGrades.trim() !== ''));

      return {
        sector,
        grades: this.campusGrades,
        showSeparator,
      };
    },
    campusGrades() {
      const minGrade = getLocaleProperty(this.campus?.grades_info?.grade_min_label, 'grade_name');
      const maxGrade = getLocaleProperty(this.campus?.grades_info?.grade_max_label, 'grade_name');

      const hasGrades = minGrade && maxGrade;
      const separator = hasGrades ? this.$t('campus-details.to') : '';

      return hasGrades ? `${minGrade} ${separator} ${maxGrade}` : '';
    },
  },
  methods: {
    ...mapActions({
      exploreCampus: 'institutions/exploreCampus',
      setShowCampusDetail: 'institutions/setShowCampusDetail',
      sendGeneralExploredCampus: 'institutions/sendGeneralExploredCampus',
    }),
    async explore() {
      this.sendGeneralExploredCampus({ campusUuid: this.campus.uuid, event: 'click_campus_card' });
      // Modify url in explorer skipping the router
      const routeRoot = window.location.href.split('?')[0];
      const useSlash = routeRoot.endsWith('/') ? '' : '/';
      const newUrl = `${routeRoot}${useSlash}school/${this.campus.campus_code}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
      trackMixPanel('open_school_profile', {
        school_id: this.campus.uuid,
        campus_code: this.campus.campus_code,
        institution_code: this.campus.institution_code,
        name: this.campus.campus_name,
      });
      this.setShowCampusDetail({ uuid: this.campus.uuid });
      this.exploreCampus({ campus: this.campus });
    },
    closeCard() {
      this.$emit('close-card');
    },
  },
};
</script>
<style lang="scss" scoped>
.campus-card {
  width: 392px;
  background-color: #F0F0F0;
  padding: 16px;
  border-radius: 4px;
  position: relative;
  z-index: 100;

  &--mobile {
    width: 96%;
    max-width: 392px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__header {
    width: 100%;
    display: flex;
  }

  &__header > div {
    width: calc(100% - 62px);
    display: flex;
    flex-direction: column;
  }

  &__header > picture {
  width: 62px;
  height: 62px;

    img {
      border-radius: 50%;
      width: 62px;
      height: 62px;
    }
  }

  &__actions {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      width: 49.2%;
    }

    & > button {
      padding: 0.5rem 1rem;
      width: 49.2%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 22px;
      letter-spacing: 0.0025em;
      color: #565656;
      background: #ffffff;
      border-radius: 4px;
      transition: 0.2s ease-in-out all;
      -webkit-transition: 0.2s ease-in-out all;
      -moz-transition: 0.2s ease-in-out all;
      -ms-transition: 0.2s ease-in-out all;
      -o-transition: 0.2s ease-in-out all;
    }

    & > button:hover {
      background-color: #5627ff;
      color: #ffffff;
      transition: 0.2s ease-in-out all;
      -webkit-transition: 0.2s ease-in-out all;
      -moz-transition: 0.2s ease-in-out all;
      -ms-transition: 0.2s ease-in-out all;
      -o-transition: 0.2s ease-in-out all;
    }
  }
}

hr {
  margin: 0.5rem 0 0.2rem 0;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
}

.reveal-animation {
  opacity: 0;
  animation: reveal 0.5s forwards var(--d)
}

@keyframes reveal {
  from {
    transform: translateY(20px);
  }
  to {
    opacity: 1; transform: none;
  }
}
</style>
