<template>
  <section
    class="current-info-bar"
    :class="mobile ? 'mt-20 mr-4' : 'd-flex mr-4'"
  >
    <div
      v-for="(btn, index) in firstRowButtons"
      :key="btn.text"
      @click="index === 0 ? $emit('showStudentSelection') : null"
      @keydown.enter="index === 0 ? $emit('showStudentSelection') : null"
    >
      <CheckButton
        :style="index === 0 ? 'margin-right: 40px' : 'margin-right: 12px'"
        :text="btn.text"
        :main="btn.main"
      />
    </div>
    <div :class="mobile ? 'mobile-info-bar' : 'd-flex'">
      <div
        v-for="(btn, index) in secondRowButtons"
        :key="btn.main"
      >
        <div :class="index === 0 ? 'd-flex mr-3' : ''">
          <CheckButton
            :style="'margin-right: 12px'"
            :text="btn.text"
            :main="btn.main"
          />
          <div v-if="separator && index === 0" class="separator" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CheckButton from '@/components/atoms/buttons/CheckButton.vue';

export default {
  name: 'SimulateCurrentInfoBar',
  components: {
    CheckButton,
  },
  props: {
    separator: {
      type: Boolean,
      default: false,
    },
    firstRowButtons: {
      type: Array,
      default: null,
    },
    secondRowButtons: {
      type: Array,
      default: null,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
