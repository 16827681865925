<template>
  <div class="content-wrap">
    <div
      class="student-menu"
      :class="{
        'student-menu--mobile': mobile,
      }"
    >
      <div class="student-menu__div__main">
        <button
          v-if="(!currentStudent || Object.keys(currentStudent).length === 0) && studentList.length === 0"
          id="student-select"
          v-t="isGuest ? 'header.guest' : 'desktop.header.add-student'"
          type="button"
          height="21"
          color="#2b5bff"
          class="student-menu__div__main__select-guest"
          :class="{ 'student-menu__div__main__select-guest--mobile': tablet }"
          @click="isGuest ? goToLogin() : addStudent()"
        />
        <v-select
          v-if="!isGuest && !mobile && studentList.length > 0"
          v-show="!isGuest"
          id="student-select"
          class="student-menu__div__main__select ml-2 mr-2"
          hide-details
          :items="allMainFilters"
          :value="getMainFilter()"
          item-text="name"
          item-value="uuid"
          solo
          dense
          item-color="#1E0C61"
          :menu-props="{ bottom: true, offsetY: true, value: showSelection }"
          append-icon="$expand"
          @change="changeCurrentStudent"
          @click="expandSelect()"
        >
          <template #selection="{ item }">
            <span
              class="student-menu__div__main__select--text"
            >
              {{ setItemName(item) }}
            </span>
          </template>
          <template slot="item" slot-scope="{ item }">
            <img
              v-if="!item.lg"
              style="margin-right: 8px"
              width="20"
              :src="require('@/assets/icons/face-primary.svg')"
              alt="Icono tipo usuario"
            />
            <span style="color: #1E0C61; font-size: 14px">
              {{ setItemName(item) }}
            </span>
          </template>
        </v-select>
        <div
          class="student-menu__filter ml-2 mr-2"
          :class="{
            'student-menu__filter--guest': isGuest,
            'student-menu__filter--mobile': mobile,
          }"
        >
          <EditMainInfoBar
            v-if="!showMobileSearchBar"
            :filter-set="filterSet"
            @showEditLocation="$emit('showEditLocation')"
            @showEditGrade="$emit('showEditGrade')"
          />
        </div>
        <!-- New select for Vacancies -->
        <div
          v-if="!mobile && !hideVacancies && false"
          class="student-menu__div__main__vacancies"
        >
          <v-select
            v-model="initialYear"
            class="student-menu__div__main ml-2 mr-2"
            :menu-props="{ bottom: true, offsetY: true }"
            solo
            dense
            item-color="#1E0C61"
            append-icon="$expand"
            hide-details
            :items="vacanciesPerYear"
            @change="setMapVacanciesFilter"
          >
            <template slot="item" slot-scope="{ item }">
              <img
                style="margin-right: 8px"
                width="20"
                :src="require('@/assets/icons/face-primary.svg')"
                alt="Icono tipo usuario"
              />
              <span class="student-menu__div__main__select--text">
                {{ item }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div class="student-menu__div__main">
        <button
          type="button"
          class="student-menu__div__more"
          :class="{
            'student-menu__div__more--selected': showFilters,
            'student-menu__div__more--mobile': mobile,
          }"
          @click="openFilters()"
        >
          <SvgIcon :icon="getFilterIcons" :size="'20'" style="margin-right: 12px" />
          {{ $t('header.filters') }}
          <div v-if="publicActiveFiltersCount > 0" class="ml-1">
            {{ '( ' + publicActiveFiltersCount + ' )' }}
          </div>
        </button>
        <CampusSearchBar
          v-if="!mobile || showMobileSearchBar"
          :mobile-width="showMobileSearchBar"
          style="margin-right: 8px"
        />
        <v-icon
          v-if="mobile"
          medium
          :color="showMobileSearchBar ? 'red' : 'white'"
          class="ml-2"
          @click="showMobileSearch"
        >
          mdi-magnify
        </v-icon>
      </div>
    </div>
    <v-dialog
      v-model="showStudentRegisterModal"
      :content-class="!mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'"
    >
      <Identification
        v-if="studentRegisterStep === 1"
        in-student-flow
        no-stepper
        is-in-map
        @backStep="showStudentRegisterModal = false"
        @nextStep="setStudentIdInfo"
      />
      <LocationFlow
        v-if="studentRegisterStep === 2"
        :select-step="1"
        back
        @backStep="studentRegisterStep = 1"
        @closeModal="showStudentRegisterModal = false"
      />
      <SelectGrades
        v-if="studentRegisterStep === 4"
        in-student-flow
        is-in-map
        @backStep="studentRegisterStep = 3"
        @sendGrade="sendGrade"
      />
    </v-dialog>
  </div>
</template>

<script>
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import CampusSearchBar from '@/components/explorer/map/CampusSearchBar.vue';
import EditMainInfoBar from '@/components/explorer/map/EditMainInfoBar.vue';
import Identification from '@/components/organisms/register/Identification.vue';
import SelectGrades from '@/components/shared/SelectGrades.vue';
import LocationFlow from '@/components/templates/LocationFlow.vue';
import trackMixPanel from '@/utils/mixpanel';
import {
  TETHER_FILTER_KEYS as FILTER_KEYS,
  TETHER_FILTER_CONTEXTS as FILTER_CONTEXTS,
} from '@/constants/filters';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'StudentMenu',
  components: {
    CampusSearchBar,
    EditMainInfoBar,
    SvgIcon,
    Identification,
    SelectGrades,
    LocationFlow,
  },
  props: {
    filterSet: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      editingOrAddingStudent: false,
      showStudentRegisterModal: false,
      showMobileSearchBar: false,
      showFilters: false,
      studentRegisterStep: 0,
      showSelection: false,
      studentIdentification: null,
      selectedLocation: null,
      vacanciesPerYear: [
        `${this.$t('desktop.header.vacancies')} ${new Date().getFullYear()}`,
        `${this.$t('desktop.header.vacancies')} ${new Date().getFullYear() + 1}`,
        `${this.$t('desktop.header.vacancies')} ${new Date().getFullYear() + 2}`,
      ],
      initialYear: `${this.$t('desktop.header.vacancies')} ${new Date().getFullYear()}`,
    };
  },
  computed: {
    ...mapGetters({
      studentList: 'authentication/students',
      currentStudent: 'authentication/currentStudent',
      isGuest: 'authentication/isGuest',
      navbarVacanciesFilter: 'filters/navbarVacanciesFilter',
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      allMainFilters: 'authentication/allMainFilters',
      publicActiveFiltersCount: 'filters/publicActiveFiltersCount',
      schools: 'institutions/schools',
      activeGrades: 'authentication/activeGrades',
      hideVacancies: 'explorer/hideVacancies',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tablet() {
      return this.$vuetify.breakpoint.md;
    },
    getFilterIcons() {
      if (this.showFilters) {
        return 'filters-white.svg';
      }
      return 'filters.svg';
    },
  },
  watch: {
    '$i18n.locale': {
      handler(locale) {
        localStorage.setItem('locale-explorer', locale);
      },
    },
    $route: {
      handler(to) {
        const editingOrAddingStudent = to.path === '/register_student';
        this.editingOrAddingStudent = editingOrAddingStudent;
      },
      immediate: true,
    },
    currentStudent: {
      handler() {
        this.showSelection = false;
      },
    },
  },
  methods: {
    ...mapActions({
      setCurrentStudent: 'authentication/setCurrentStudent',
      navigateTo: 'explorer/navigateTo',
      retrieveCampuses: 'institutions/retrieveCampuses',
      retrieveFavorites: 'favorites/retrieveListFavorites',
      retrieveExploredCampuses: 'institutions/retrieveExploredCampuses',
      retrieveExploredSchoolCard: 'institutions/retrieveExploredSchoolCard',
      error: 'utils/error',
      setForcedStep: 'userRegister/setForcedStep',
      setStudents: 'newStudentRegister/setStudents',
      setFilter: 'filters/setFilter',
      retrieveVacancies: 'institutions/retrieveVacancies',
    }),
    getMainFilter() {
      if (Object.keys(this.currentStudent).length > 0) {
        return this.allMainFilters.find((filter) => filter.uuid === this.currentStudent.uuid);
      }
      return this.allMainFilters.find((filter) => filter.lg === true);
    },
    setVacanciesFilter({ value }) {
      this.setFilter({
        context: FILTER_CONTEXTS.NAVBAR,
        key: FILTER_KEYS.HAS_VACANCIES,
        value,
      });
    },
    sendGrade(selectedOption) {
      const studentInfo = {
        uuid: this.studentList[0].uuid,
        gradeInterest: selectedOption.grades,
        stagesInterest: selectedOption.stages,
        location: this.selectedLocation,
      };
      this.setStudents({ studentsForm: studentInfo, current: false })
        .then(() => {
          this.studentRegisterStep -= 1;
          this.showStudentRegisterModal = false;
        })
        .catch((error) => {
          this.error(this.$getError(error).reason);
        });
    },
    setStudentIdInfo(student) {
      this.studentIdentification = student;
      this.studentRegisterStep = 2;
    },
    expandSelect() {
      this.showSelection = !this.showSelection;
    },
    setMapVacanciesFilter(item) {
      const year = Number(item.split(' ')[1]);
      this.vacanciesLoader = true;
      const vacanciesValue = new Set([year]);
      this.setVacanciesFilter({ value: vacanciesValue });
      this.vacanciesLoader = false;
      this.updateVacancies();
    },
    updateVacancies() {
      const yearSelected = Array.from(this.navbarVacanciesFilter);
      const { grades } = this.activeGrades;

      this.retrieveVacancies({
        year: yearSelected[0],
        grade: grades?.length > 0 ? grades : null,
        campuses: this.schools.map((school) => school.campus_code),
      });
    },
    setItemName(item) {
      if (item) {
        if (item.name) {
          return item.name.split(' ', 1)[0];
        }
        if (item.first_name) {
          return item.first_name.split(' ', 1)[0];
        }
        if (item.firstName) {
          return item.firstName.split(' ', 1)[0];
        }
      }
      return '';
    },
    changeCurrentStudent(uuid) {
      if (uuid === this.legalGuardianUUID) {
        this.setCurrentStudent({ student: {} })
          .then(() => this.retrieveCampuses({ callback: null }))
          .then(() => {
            this.showSelection = false;
          });
      } else {
        const student = this.studentList.find((st) => st.uuid === uuid);
        if (student.address && student.address[0].address_lat && student.address[0].address_lon) {
          this.navigateTo({
            lat: student.address[0].address_lat,
            lng: student.address[0].address_lon,
          });
        }
        this.setCurrentStudent({ student })
          .then(() => {
            this.showSelection = false;
            this.retrieveFavorites();
          })
          .then(() => this.retrieveExploredCampuses())
          .then(() => this.retrieveExploredSchoolCard())
          .catch((error) => this.error(error));
      }
      this.showSelection = false;
    },
    addStudent() {
      if (!this.isGuest) {
        this.showStudentRegisterModal = true;
        this.studentRegisterStep = 1;
      }
    },
    openFilters() {
      this.$emit('showFilterModal');
      trackMixPanel('map_filter_click');
    },
    showMobileSearch() {
      if (this.showMobileSearchBar) {
        this.showMobileSearchBar = false;
        return this.showMobileSearchBar;
      }
      this.showMobileSearchBar = true;
      return this.showMobileSearchBar;
    },
    goToLogin() {
      this.setForcedStep({ step: 0 }).then(() => {
        this.$router.push('/login');
      });
    },
  },
};
</script>
