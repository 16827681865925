<template>
  <section>
    <section>
      <SimulateCurrentInfoBar
        separator
        :first-row-buttons="currentStudentButtons"
        :second-row-buttons="currentStudentGradeButtons"
        @showStudentSelection="$emit('showStudentSelection')"
      />
    </section>
    <div class="d-flex simulation-columns--background">
      <div class="col">
        <SimulationSelector />
      </div>
      <div class="col">
        <SimulationList />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SimulationList from '@/components/explorer/simulate/SimulationList.vue';
import SimulationSelector from '@/components/explorer/simulate/SimulationSelector.vue';
import SimulateCurrentInfoBar from '@/components/molecules/bars/SimulateCurrentInfoBar.vue';
import '@/styles/dashboard/simulation.scss';
import utils from '@/utils';
import { getLocaleProperty } from '@/utils/locale';

export default {
  name: 'SimulationSection',
  components: {
    SimulationSelector,
    SimulationList,
    SimulateCurrentInfoBar,
  },
  computed: {
    ...mapGetters({
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      currentStudent: 'authentication/currentStudent',
      students: 'authentication/students',
      grades: 'options/grades',
      activeGrades: 'authentication/activeGrades',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getCurrentGrade() {
      const [lookupGrade] = this.activeGrades.grades;
      const gradeLabel = this.grades.find((grade) => grade.id === lookupGrade);
      if (gradeLabel) {
        return getLocaleProperty(gradeLabel, 'grade_name');
      }
      return null;
    },
    currentStudentButtons() {
      const buttonSection = [
        {
          text: 'desktop.simulation.nav-bar.student-question',
          main: true,
        },
      ];
      return buttonSection;
    },
    currentStudentGradeButtons() {
      const buttonSection = [
        { text: this.currentStudent?.first_name ?? 'simulation.guest' },
        { text: this.getCurrentGrade },
        { text: 'desktop.simulation.nav-bar.priority-student' },
      ];
      return buttonSection;
    },
  },
  mounted() {
    if (!this.isGuest && this.students && this.students.length > 0) {
      this.setCurrentStudent({ student: this.students[0] });
    }
  },
  async created() {
    let getDetails = false;
    if (this.favoriteSchoolsDetails && this.favoriteSchoolsDetails.length > 0) {
      getDetails = utils.isGetDetailsFavoritesSchools(this.favoriteSchoolsDetails);
    }

    if (getDetails && this.favoriteSchoolsDetails.length > 0) {
      await this.retrieveListFavorites({ isInLogin: false, getDetails: true });
    } else {
      this.setLoadingSchoolsDetails(false);
    }
  },
  methods: {
    ...mapActions({
      retrieveListFavorites: 'favorites/retrieveListFavorites',
      setLoadingSchoolsDetails: 'favorites/setLoadingSchoolsDetails',
      setCurrentStudent: 'authentication/setCurrentStudent',
    }),
  },
};
</script>
